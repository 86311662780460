import React, { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import './navbar.scss'
import logo from '../../../media/img/Promtech-logo.png'
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import $ from 'jquery'
import { useWindowSize } from './useResize.hook'
import { ReactComponent as RecIcon} from "./assets/rec.icon.svg";
import { pageTargets,targetFunc } from './../../../ya-metrics';



export default function Navbar(props) {

  const { windowSize } = useWindowSize()

  const location = useLocation();

  // Открытая страница на сайте
  useEffect(() => {
    targetFunc(`page_path_${location.pathname}`)
  }, [location])


  const directHashList = [
                          '#main_tf', // РМ основная компания
                          '#main_tb', 
                          '#advanced', // РМ широкая
                          '#analysis_tf', // РМ анализы
                          '#analysis_tb',

                          '#mobile',

                          '#parameters', // РМ Свойства

                          '#retarget', // Ретаргет
                          '#target_comp', // Таргет по конкурентам 

                          '#plants',

                          '#change',
                          '#changeOil', // Залив, долив, замена
                          '#replacement', // Замена
                          '#filling', // Залив
                          '#topping', // Залив


                          '#to', 
                          '#current' ,
                          '#capital',
                          '#install',
                          ]

  // Переход с рекламной компании
  useEffect(() => {
    if(directHashList.includes(location.hash)) {
      targetFunc(`page_hash_${location.hash}`)
    }
  }, [location])
  
  

  useEffect(() => {
    let date = moment.tz(moment(), 'Europe/Moscow');

    let day = date.day()
    let hours = date.format('HH');

    if (directHashList.includes(location.hash) || localStorage.getItem('client') === 'yes') {
      // eslint-disable-next-line no-restricted-globals
      history.pushState('', document.title, window.location.pathname)
      localStorage.setItem('client', 'yes')
      // if (day >= 1 && day <= 5) {
      //   if (hours >= 6 && hours <= 22) {
      //   // if (hours >= 6 && hours < 10 || hours >= 15 && hours <= 22) {
      //     props.changeNumber('+7 (495) 363-7129')
      //   } else {
      //     props.changeNumber('+7 (499) 324-8947')
      //   }
      // } else {
      //   props.changeNumber('+7 (495) 363-7129')
      // }
    }


    if (location.pathname === '/plants/moim/' ||
      location.pathname === '/plants/plant-ef/' ||
      location.pathname === '/plants/plant-reg/' ||
      location.pathname === '/plants/plant-mob/') {

    } else {
      changepage(location.pathname)
    }
  }, [location]);


  const calc = (val) => {
    if (document.documentElement.clientWidth < 1920) {
      return val * 1024 / 1920 + (val - val * 1024 / 1920) * ((document.documentElement.clientWidth - 1024) / (1920 - 1024))
    } else return val
  }



  const defaultPagesList = [
    { name: 'Главная', url: '/' },
    { name: 'О компании', url: '/company/' },
    { name: 'Установки', url: '/plants/' },
    { name: 'Контакты', url: '/contact/' },
    { name: 'Услуги', url: '/services/' },
    { name: 'Ремонт и ТО', url: '/trans/' },
    // { name: 'Технология', url: '/techno/' },
    // { name: 'Анализы', url: '/analysis/' },
    { name: 'Документы', url: '/archive/' },
  ]


  const [open, setOpen] = useState(false)
  useEffect(() => {
    let burgerLines = document.querySelectorAll('.openClose')
    let headerLinks = document.querySelector('.header-links')
    let links = document.querySelectorAll('.header-link')
    if (open) {
      if(document.querySelector('.quick')){
        document.querySelector('.quick').style.display = 'none'
      }
      headerLinks.style.transform = 'translateX(0)'
      burgerLines.forEach(line => {
        line.style.width = `${calc(35)}px`
      })
      links.forEach((link, index) => {
        if (index === 4) {
          link.style.opacity = 1
        }
        if (index === 5) {
          setTimeout(() => {
            link.style.opacity = 1
          }, 150);
        }
        if (index === 6) {
          setTimeout(() => {
            link.style.opacity = 1
          }, 200);
        }
      })
    } else {
      if(location.pathname !== '/contact/'){
        setTimeout(() => {
          if(document.querySelector('.quick')){
            document.querySelector('.quick').style.display = 'flex'
          }
        }, 200);
      }
      document.querySelector('.header-links').style.transform = `translateX(${calc(595)}px)`
      burgerLines.forEach((line, index) => {
        if (index === 1)
          line.style.width = `${calc(24)}px`
        if (index === 2)
          line.style.width = `${calc(12)}px`
      })
      links.forEach((link, index) => {
        if (index === 4 || index === 5 || index === 6) {
          link.style.opacity = 0
        }
      })
    }
  }, [open, windowSize])

  const openClose = () => {
    targetFunc(pageTargets.navbar.burger);
    setOpen(!open)
  }

  const [Pages, setPages] = useState(defaultPagesList)

  const changepage = (page) => {
    if(page !== '/services/' && page !== '/trans/'){
      let copyPages = JSON.parse(JSON.stringify(defaultPagesList))
      let pageIndex
      copyPages.forEach((copyPage, i) => {
        if (copyPage.url === page) pageIndex = i
      });
      copyPages.splice(0, 0, copyPages.splice(pageIndex, 1)[0]);
      setPages([...copyPages])
    }
    setOpen(false)
  }



  useEffect(() => {
    const main = document.querySelector(".main");
    const hl = document.querySelector(".header-line");
    const header = document.querySelector(".header");

    if (document.documentElement.clientWidth > 768) {
      if (location.pathname !== '/') {
        header.style.transition = 'none'
        header.style.boxShadow = '0px 0px 24px #00000029'
        if(document.querySelectorAll('.header-menu')){
          document.querySelectorAll('.header-menu').forEach(menu => {
            menu.style.marginTop = `${calc(50)}px`
          });
        }
      } else {
        if(document.querySelectorAll('.header-menu')){
          document.querySelectorAll('.header-menu').forEach(menu => {
            menu.style.marginTop = `${calc(30)}px`
          });
        }
        header.style.transition = 'none'
        header.style.boxShadow = '0px 0px 0px #00000029'
        hl.style.opacity = 1
        window.addEventListener("scroll", (event) => {
          if (main.getBoundingClientRect().top < calc(150)) {
            if(document.querySelectorAll('.header-menu')){
              document.querySelectorAll('.header-menu').forEach(menu => {
                menu.style.marginTop = `${calc(50)}px`
              });
            }
            header.style.transition = 'all 0.15s'
            header.style.boxShadow = '0px 0px 24px #00000029'
            hl.style.opacity = 0
          } else {
            if(document.querySelectorAll('.header-menu')){
              document.querySelectorAll('.header-menu').forEach(menu => {
                menu.style.marginTop = `${calc(30)}px`
              });
            }
            header.style.transition = 'none'
            header.style.boxShadow = '0px 0px 0px #00000029'
            hl.style.opacity = 1
          }
        });
      }
    }
  }, [location])



  useEffect(() => {
    props.setOpenerType('close')
    if(document.querySelector('.quick')){
      if(location.pathname === '/contact/'){
        document.querySelector('.quick').style.display = 'none'
      } else {
        document.querySelector('.quick').style.display = 'flex'
      }
    }
  }, [location])


  useEffect(() => {
    if (document.documentElement.clientWidth < 768 && document.querySelector('.mobile-header') && $('.mobile-footer')) {
      document.querySelector('.mobile-header').style.display = 'flex'
      if (document.querySelector('.mobile-header') && document.querySelector('.mobile-footer')) {
        window.addEventListener("scroll", (event) => {
          var top = $(window).scrollTop();
          var height = $(window).height();
          var set = $('.mobile-footer').offset().top;
          var outerHeight = $('.mobile-footer').outerHeight();
          var dheight = $(document).height();
          if (top + height >= set || top + height == dheight || outerHeight + set < top) {
            document.querySelector('.mobile-header').style.display = 'none'
          } else {
            document.querySelector('.mobile-header').style.display = 'flex'
          }

        })
      }
    }

  }, [location])

  useEffect(() => {
    document.querySelectorAll('.header-link').forEach(element => {
      element.onmouseover =  (e) => {
        document.querySelector('.header-link-active').classList.remove('header-link-line')
      };
      element.onmouseout =  (e) => {
        document.querySelector('.header-link-active').classList.add('header-link-line')
      };
    })

    document.querySelectorAll('.header-link-menu').forEach(element => {
      element.onmouseover = function() {
        this.querySelector('.header-menu').style.display = 'block';
      };
      element.onmouseout = function() {
        this.querySelector('.header-menu').style.display = 'none';
      };
    });
    


  }, [Pages])

  useEffect(() => {
    if(document.querySelectorAll('.header-menu')){
      document.querySelectorAll('.header-menu').forEach(menu => {
        menu.style.display = 'none';
      });
    }
  }, [location])
  
  useEffect(() => {
    if(document.querySelector('#navbarPhone')){
      document.querySelector('#navbarPhone').addEventListener('mousedown', ()=>{targetFunc(pageTargets.navbar.phone)});
    }
}, [])

const isPrerender = navigator.userAgent === "ReactSnap";



  return (
    <>
      <div class="header">
        <div class="header-content">
          <div className="header-logo-block">
            <div class="header-logo">
              <Link to="/" >
                <img src={logo} alt=""></img>
              </Link>
            </div>
            {!isPrerender ?
            <div className="quick">
              <div id='navbarPhone' className="phone" >{props.phoneNumber}</div>
              <div className="button" onClick={() => {
                targetFunc(pageTargets.navbar.formOpen);
                props.callRequest();
              }}>Заказать звонок</div>
            </div> : <></>}
          </div>

          <div className="header-links-wrap">
            <div className="header-links">
              {
                Pages.map((page, i) => {
                  if (i === 0) {
                    return (
                      <Link to={page.url} className="header-link header-link-active header-link-line" key={`link-${i}`} onClick={()=>targetFunc(pageTargets.navbar.currentPage)}>
                        {page.name}
                        {!isPrerender ? <div className="tooltip">Текущая страница</div> : <></>}
                      </Link>
                    )
                  } else if(page.url === '/plants/') {
                    return (
                      <Link to={page.url} className="header-link header-link-menu" key={`link-${i}`}>
                        {page.name}
                        <div className="icon"><RecIcon/></div>
                        <div className="header-menu-wrap">
                        <div className="header-menu">
                          <div className="links links-larger">
                          <div className="link-wrap"><Link to={'/plants/'} className="link">Все установки очистки</Link></div>
                          <div className="link-wrap"><Link to={'/plants/plant-1.0/'} className="link">Установка очистки 1 м3/ч</Link></div>
                          <div className="link-wrap"><Link to={'/plants/plant-2.0/'} className="link">Установка очистки 2 м3/ч</Link></div>
                          <div className="link-wrap"><Link to={'/plants/plant-4.0/'} className="link">Установка очистки 4 м3/ч</Link></div>
                          <div className="link-wrap"><Link to={'/plants/plant-ef/'} className="link">Установка очистки</Link></div>
                          <div className="link-wrap"><Link to={'/plants/plant-reg/'} className="link">Установка регенерации 1 м3/ч</Link></div>
                          <div className="link-wrap"><Link to={'/plants/plant-mob/'} className="link">Мобильный комплекс очистки</Link></div>
                          </div>
                        </div>
                        </div>
                      </Link>
                    )
                  } else if(page.url === '/services/') {
                    return (
                      <Link to={page.url} className="header-link header-link-menu" key={`link-${i}`}>
                        {page.name}
                        <div className="icon"><RecIcon/></div>
                        <div className="header-menu-wrap">
                        <div className="header-menu">
                          <div className="links links-large">
                          <div className="link-wrap"><Link to={'/services/cleaning/'} className="link">Очистка масла</Link></div>
                          <div className="link-wrap"><Link to={'/services/drying/'} className="link">Сушка масла</Link></div>
                          <div className="link-wrap"><Link to={'/services/filtration/'} className="link">Фильтрация масла</Link></div>
                          <div className="link-wrap"><Link to={'/services/degasification/'} className="link">Дегазация масла</Link></div>
                          <div className="link-wrap"><Link to={'/services/regeneration/'} className="link">Регенерация масла</Link></div>
                          <hr/>
                          <div className="link-wrap"><Link to={'/analysis/'} className="link">Каталог анализов</Link></div>
                          <div className="link-wrap"><Link to={'/services/testing/'} className="link">Испытание масла</Link></div>
                          <div className="link-wrap"><Link to={'/services/hga/'} className="link">ХАРГ анализ масла</Link></div>
                          <hr/>
                          <div className="link-wrap"><Link to={'/services/replacement/'} className="link">Замена масла</Link></div>
                          <div className="link-wrap"><Link to={'/services/filling/'} className="link">Заливка масла</Link></div>
                          <div className="link-wrap"><Link to={'/services/topping/'} className="link">Долив масла</Link></div>
                          </div>
                        </div>
                        </div>
                      </Link>
                    )
                  } else if(page.url === '/trans/') {
                    return (
                      <Link to={page.url} className="header-link header-link-menu" key={`link-${i}`}>
                        {page.name}
                        <div className="icon"><RecIcon/></div>
                        <div className="header-menu-wrap">
                        <div className="header-menu">
                          <div className="links links-large">
                          <div className="link-wrap"><Link to={'/trans/to/'} className="link">Техобслуживание трансформаторов</Link></div>
                          <div className="link-wrap"><Link to={'/trans/current/'} className="link">Текущий ремонт трансформаторов</Link></div>
                          <div className="link-wrap"><Link to={'/trans/capital/'} className="link">Капитальный ремонт трансформаторов</Link></div>
                          <div className="link-wrap"><Link to={'/trans/install/'} className="link">Монтаж силовых трансформаторов</Link></div>
                          </div>
                        </div>
                        </div>
                      </Link>
                    )
                  } 
                  else {
                    return <Link to={page.url} className="header-link" key={`link-${i}`}>{page.name}</Link>
                  }
                })
              }
            </div>
          </div>
          <div className="OC-wrap" onClick={openClose}>
          <div className="openClose-wrap" >
            <div className="openClose"></div>
            <div className="openClose"></div>
            <div className="openClose"></div>
          </div>
          </div>
        </div>
      </div>

      {!isPrerender ? <div className='mobile-header'>
        <div className="page-link"><Link to={'/'}>Главная</Link></div>
        <div className="page-link"><Link to={'/company/'}>О компании</Link></div>
        <div className="page-link"><Link to={'/plants/'}>Установки</Link></div>
        <div className="page-link"><Link to={'/contact/'}>Контакты</Link></div>
        <div className="menu-wrap" onClick={() => props.setOpenerType('menu')}><div className="menu" /></div>
      </div> : <></>}

      {/* <div className="menu-open" ref={openerWrap}>
    <div className="menu-wrapper" onClick={()=>setOpenMobile(false)}> 
      <div className="menu-body" 
        ref={openerBody} 
        onClick={(event)=>{
          event.stopPropagation();
          setOpenMobile(true)
        }}
      >
        <div 
          className="close" 
          onClick={(event)=>{
            event.stopPropagation();
            setOpenMobile(false)
          }}/>
        <div className="links">
          <div className="link"><Link to={'/'}>Главная страница</Link></div>
          <div className="link"><Link to={'/company'}>О компании</Link></div>
          <div className="link"><Link to={'/plants'}>Каталог установок</Link></div>
          <div className="link"><Link to={'/plants/moim'}>Установка МОИМ-4.0</Link></div>
          <div className="link"><Link to={'/plants/ksor'}>Установка КСОР-1</Link></div>
          <div className="link"><Link to={'/contact'}>Контакты</Link></div>
          <div className="link"><Link to={'/archive'}>Документы</Link></div>
        </div>
        <div className="contacts">
          <div className="phone">{props.phoneNumber}</div>
          <div className="email">info@promtech-company.ru</div>
        </div>
      </div>
    </div>
    </div> */}

    </>
  )
}
